<template>
  <div class="column is-size-3 has-text-white is-hidden-mobile">
    <router-link id="hov" class="has-text-white" style="float: right;" to="/">→ réalisations</router-link>
  </div>

  <div class="column is-size-5 has-text-white is-hidden-tablet">
    <router-link id="hov" class="has-text-white" style="float: right;" to="/">→ réalisations</router-link>
  </div>
  <div class="column">
    <br>
    <div class="is-size-3  has-text-left is-hidden-mobile">
      <p class="isPink">Je fais des images.</p> 
      <p>Je traduis des idées, des intentions, des doutes en mots puis les mots en images.</p>
      <p>Je les mélange, je les assemble, les fais se rencontrer, ensemble.</p> 
      <p>De cet ensemble d’images, j’extrais une grammaire :</p>
      <p>un système composé de formes, de couleurs, de caractères typographiques.</p>
      <p>Je l’agence dans un format, je mets en page(s) selon une syntaxe. Taille, répétition, </p>
      <p>fréquence, rythme, angle, direction, position, symétrie, équilibre, contraste,</p> 
      <p>hiérarchie. Je coupe, je colle, combine, compose enfin je trie, sélectionne, je sacrifie.</p>
      <p class="isGreen">Je fais des images.</p>
    </div>

    <div class="is-size-5  has-text-left is-hidden-tablet">
      <p class="isPink">Je fais des images.</p> 
      <p>Je traduis des idées, des intentions, des doutes en mots puis les mots en images.</p>
      <p>Je les mélange, je les assemble, les fais se rencontrer, ensemble.</p> 
      <p>De cet ensemble d’images, j’extrais une grammaire :</p>
      <p>un système composé de formes, de couleurs, de caractères typographiques.</p>
      <p>Je l’agence dans un format, je mets en page(s) selon une syntaxe. Taille, répétition, </p>
      <p>fréquence, rythme, angle, direction, position, symétrie, équilibre, contraste,</p> 
      <p>hiérarchie. Je coupe, je colle, combine, compose enfin je trie, sélectionne, je sacrifie.</p>
      <p class="isGreen">Je fais des images.</p>
    </div>

    <div class="has-text-left pt-6">
      <a v-bind:href="`mailto:bonjour@fannylaulaigne.fr`">
        <img   src="../assets/img/fanny_laulaigne-about03.gif">
      </a>
    </div>
  </div>
</template>

<style>

#hov:hover {
  background-color: black;
  color:  #FF46E6 !important;
}

.isPink {
  color: #FF46E6;
}
.isGreen {
  color: #00FA00;
}

</style>